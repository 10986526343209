/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import Component from "vue-class-component"

Vue.config.productionTip = false;

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
